<template>
    <div
        class="leaderboard-table-container"
    >
        <div
            v-for="(rank, index) in ranks"
            :key="rank.id"
            class="rank-row"
            :class="{
                'current-player': rank.id === player.id ,
                'margin-bottom': index === 4 && isRanksHigherThanTen
            }"
        >
            <span v-if="showDots(index)" class="ellipsis-container">
                <span
                    v-for="ellipsisIndex in 3"
                    :key="ellipsisIndex"
                />
            </span>
            <span class="position desktop-only">
                #<span>{{ rank.rank }}</span>
            </span>
            <div class="avatar-container">
                <avatar
                    :avatar="rank.avatar? rank.avatar : null"
                />
                <img
                    v-if="rank.rank === 1 && leaderboardConfig.firstPlayerIconUrl"
                    :src="leaderboardConfig.firstPlayerIconUrl"
                    alt="first player icon"
                    class="winner-badge"
                >
                <img
                    v-if="rank.rank === 2 && leaderboardConfig.secondPlayerIconUrl"
                    :src="leaderboardConfig.secondPlayerIconUrl"
                    alt="second player icon"
                    class="winner-badge"
                >
                <img
                    v-if="rank.rank === 3 && leaderboardConfig.thirdPlayerIconUrl"
                    :src="leaderboardConfig.thirdPlayerIconUrl"
                    alt="third player icon"
                    class="winner-badge"
                >
            </div>
            <div class="player-info">
                <span class="player-name">
                    {{ rank.name }}
                </span>
                <div class="points-container">
                    <div
                        class="points-earned"
                    >
                        <span>
                            {{ $t('leaderBoardRoute.points') }}:
                        </span>
                        <div class="points-text-container">
                            <span class="points-text">{{ rank.totalScore }}</span>
                            <font-awesome-icon :icon="[ 'fas', 'star' ]" class="star points-text" />
                        </div>
                    </div>
                    <div
                        class="points-redeemed"
                    >
                        <div class="time-spent-container">
                            <span class="time-spent">{{ rank.digitString }}</span>
                            <timer-svg class="timer-icon" color="star-color" />
                        </div>
                    </div>
                </div>
            </div>
            <img
                v-if="showChallengeIcon(rank)"
                :src="leaderboardConfig.challengeIconUrl"
                alt="challenge icon"
                class="challenge-icon pointer"
                title="Challenge"
                @click="challengePlayer(rank.challengerId)"
            >
            <span v-else class="challenge-icon" />
        </div>
    </div>
</template>

<script>
    import Avatar from '@/components/Avatar'
    import TimerSvg from '@/components/svg/TimerSVG'
    export default {
        components: { Avatar, TimerSvg },
        computed: {
            ranks() {
                return this.$store.getters.getRanks
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            playerRank() {
                return this.ranks.find(rank => rank.id === this.player.id)?.rank || -1
            },
            isRanksHigherThanTen() {
                const highestRank = Math.max(...this.ranks.map(rank => rank.rank), 0)
                return highestRank > 10
            },
            leaderboardConfig() {
                return this.$store.getters.getLeaderboardConfig
            },
            featureConfig() {
                return this.$store.getters.getConfigFeatures
            },
            isArenaEnabled() {
                return this.featureConfig?.challengesEnabled
            }
        },
        methods: {
            showDots(index) {
                return index === 4 && this.ranks.length > 5 && this.playerRank > 5
            },
            challengePlayer(joinerId) {
                if (!joinerId) {
                    return
                }
                this.$router.push({ name: 'Challenges', query: { joinerId } })
            },
            showChallengeIcon(rank) {
                return this.isArenaEnabled && rank.challengesParticipation && rank.challengerId && rank.id !== this.player.id
            }
        }
    }
</script>

<style scoped>
.leaderboard-table-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.45rem;
}

.rank-row {
    --ellipsis-container-height: 3rem;

    width: 92%;
    display: flex;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.5rem;
    font-weight: 700;
    font-size: 1rem;
    border-radius: 4px;
    gap: 1rem;
    position: relative;
    margin: 0 auto;
}

.points-container .points-text,
.time-spent-container .time-spent {
    font-size: 0.85rem;
    font-weight: 700;
    line-height: 0.85rem;
    color: inherit;
}

.rank-row:nth-child(1),
.rank-row:nth-child(2),
.rank-row:nth-child(3),
.rank-row:nth-child(4),
.rank-row:nth-child(5) {
    background: var(--leaderboard-top-5-background);
    color: var(--leaderboard-top-5-text);
}

.rank-row:nth-child(6),
.rank-row:nth-child(7),
.rank-row:nth-child(8),
.rank-row:nth-child(9),
.rank-row:nth-child(10) {
    background: var(--leaderboard-last-5-background);
    color: var(--leaderboard-last-5-text);
}

/* Leaderboard Ellipsis Start */
.rank-row.margin-bottom {
    margin-bottom: var(--ellipsis-container-height);
}

.ellipsis-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: calc(var(--ellipsis-container-height) + 0.45rem);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.ellipsis-container span {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: var(--main-content-text);
}

/* Leaderboard Ellipsis End */

.rank-row.current-player {
    background: var(--leaderboard-current-player-background) !important;
    color: var(--leaderboard-current-player-text) !important;
    box-shadow: 0 0 5px 1px var(--leaderboard-current-player-background) !important;
}

.current-player .points-container .points-text,
.current-player .time-spent-container .time-spent {
    --leaderboard-current-player-text: #fff;

    color: var(--leaderboard-current-player-text) !important;
}

.avatar-container {
    max-width: 50px;
    position: relative;
}

.winner-badge {
    position: absolute;
    bottom: -10%;
    right: -10%;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px rgb(0 0 0 / 20%));
}

.challenge-icon {
    width: 2rem;
    height: 2rem;
    margin-left: auto;
    margin-right: 0.5rem;
}

.pointer {
    cursor: pointer;
}

.player-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.player-info .player-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 17ch;
}

.points-container {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
}

.points-container .points-earned,
.points-container .points-redeemed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
}

.points-text-container,
.time-spent-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.timer-icon {
    width: 0.85rem;
    height: 0.85rem;
}

svg.star path {
    color: var(--star-color);
}

.right-container {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.position {
    letter-spacing: 1px;
    font-size: 1.1rem;
    font-weight: normal;
}

.position span {
    font-size: 1.5rem;
    font-weight: bold;
}

@media screen and (min-width: 768px) {
    .rank-row {
        width: 100%;
        padding: 0.75rem 1rem 0.75rem 0.75rem;
        font-size: 1.1rem;
        gap: 1.25rem;
    }

    .avatar-container {
        max-width: 55px;
    }

    .player-info {
        gap: 0.25rem;
    }

    .player-info .player-name {
        max-width: unset;
    }

    .points-container {
        flex-direction: row;
        gap: 0.5rem;
    }

    .right-container {
        gap: 1.5rem;
    }

    .position {
        font-size: 1.25rem;
    }

    .position span {
        font-size: 1.75rem;
    }
}

@media screen and (min-width: 1216px) {
    .player-info {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;
    }

    .player-info .player-name {
        overflow: visible;
        width: 220px;
        text-align: left;
    }

    .points-container {
        justify-content: space-between;
        font-size: 0.85rem;
        min-width: 270px;
    }

    .points-container .points-earned,
    .points-container .points-redeemed {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
    }

    .points-text-container,
    .time-spent-container {
        gap: 0.5rem;
        min-width: 75px;
        justify-content: flex-end;
    }

    .points-container .points-text,
    .time-spent-container .time-spent {
        font-size: 1rem;
        line-height: 1rem;
    }

    .timer-icon {
        width: 1.1rem;
        height: 1.1rem;
    }

    .position {
        font-weight: bold;
    }
}

</style>
