<template>
    <div
        v-if="ranks && ranks.length > 0"
        class="leaderboard-container"
    >
        <div align-h="start" class="header-top pb-2 ml-3 mr-3">
            <span class="text-profile-title-first vertical-align mr-2">{{ $t('leaderBoardRoute.headerFirstText') }}</span>
            <span v-if="hasPlayerPlayed" class="text-profile-title-second vertical-align">
                {{ $t('leaderBoardRoute.headerSecondText', {totalPlayers}) }}
            </span>
            <span v-else class="text-profile-title-second vertical-align">
                {{ $t('leaderBoardRoute.notPlayedYet') }}
            </span>
        </div>
        <div class="leaderboard-info-container">
            <span v-if="currentPlayerPosition" class="congratulation-text">
                <span v-if="totalPoints !== 0">{{ $t('leaderBoardRoute.congratulationText') }} </span>
                <span>{{ $t('leaderBoardRoute.rankingText') }} </span>
                <span class="ranking">{{ currentPlayerPosition }}</span>!
            </span>
            <div class="star-points">
                <span class="points-text">{{ totalPoints }}</span>
                <font-awesome-icon :icon="[ 'fas', 'star' ]" class="star points-text header-star" />
            </div>
        </div>
        <div class="table-container">
            <leader-board-table />
        </div>
    </div>
</template>

<script>

    import LeaderBoardTable from '@/components/LeaderBoardTable'
    export default {
        name: 'LeaderBoard',
        components: { LeaderBoardTable },

        computed: {
            ranks() {
                return this.$store.getters.getRanks
            },
            totalPlayers() {
                return this.$store.getters.getTotalPlayers
            },
            player() {
                return this.$store.getters.getCurrentUser
            },
            currentPlayerPosition() {
                if (this.ranks.find(rank => rank.id === this.player.id)) {
                    return this.ranks.find(rank => rank.id === this.player.id).rank
                }
                return null
            },
            totalPoints() {
                return this.player.totalScore
            },
            hasPlayerPlayed() {
                return this.ranks.find(rank => rank.id === this.player.id)
            }

        },
        created() {
            this.$store.dispatch('fetchRanks')
        }
    }
</script>

<style lang="scss" scoped>
.leaderboard-container {
    padding: 1rem 0 2rem;
    width: 100%;
}

.leaderboard-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 0.5rem;
    text-align: left;
}

.star-points {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.header-top {
    padding-left: 0;
    text-align: left;
    color: var(--main-content-text);
    border-bottom: 2px solid var(--main-content-text);
}

.text-profile-title-first {
    font-weight: 700;
    font-size: 1.5rem;
}

.text-profile-title-second {
    font-weight: 300;
    font-size: 1rem;
}

.congratulation-text {
    font-size: 1.3rem;
    vertical-align: middle;
    color: var(--main-content-text);
}

.ranking {
    color: var(--main-content-text);
    font-weight: bold;
}

.points-text {
    font-weight: 700;
    font-size: 2rem;
    color: var(--main-content-text);
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.points-cell {
    display: flex;
    justify-content: center;
    align-items: center;
}

.star {
    transform: translateY(-10%);
    filter: drop-shadow(0 0 4px rgb(0 0 0 / 25%));
}

.header-star {
    transform: translateY(-5px);
}

svg.star path {
    color: var(--star-color);
}

@media screen and (min-width: 768px) {
    .table-container {
        padding: 0 1rem;
    }
}

@media screen and (min-width: 992px) {
    .leaderboard-info-container {
        padding: 1.5rem 2rem;
    }

    .congratulation-text {
        font-size: 1.5rem;
    }

    .points-text {
        font-size: 2.5rem;
    }
}
</style>
