<template>
    <svg width="76" height="88" viewBox="0 0 76 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            class="svg"
            :class="{'blue': color ==='navigation-icon-color', 'white': color === 'white', 'star': color === 'star-color'}"
            d="M29.6667 8.50002C28.4861 8.50002 27.4972 8.10002 26.7 7.30002C25.9 6.5028 25.5 5.51391 25.5 4.33335C25.5 3.1528 25.9 2.16252 26.7 1.36252C27.4972 0.565298 28.4861 0.166687 29.6667 0.166687H46.3333C47.5139 0.166687 48.5042 0.565298 49.3042 1.36252C50.1014 2.16252 50.5 3.1528 50.5 4.33335C50.5 5.51391 50.1014 6.5028 49.3042 7.30002C48.5042 8.10002 47.5139 8.50002 46.3333 8.50002H29.6667ZM38 54.3334C39.1806 54.3334 40.1708 53.9333 40.9708 53.1334C41.7681 52.3361 42.1667 51.3472 42.1667 50.1667V33.5C42.1667 32.3195 41.7681 31.3292 40.9708 30.5292C40.1708 29.732 39.1806 29.3334 38 29.3334C36.8194 29.3334 35.8306 29.732 35.0333 30.5292C34.2333 31.3292 33.8333 32.3195 33.8333 33.5V50.1667C33.8333 51.3472 34.2333 52.3361 35.0333 53.1334C35.8306 53.9333 36.8194 54.3334 38 54.3334ZM38 87.6667C32.8611 87.6667 28.0167 86.6778 23.4667 84.7C18.9194 82.7195 14.9444 80.0278 11.5417 76.625C8.13889 73.2222 5.44722 69.2472 3.46667 64.7C1.48889 60.15 0.5 55.3056 0.5 50.1667C0.5 45.0278 1.48889 40.1834 3.46667 35.6334C5.44722 31.0861 8.13889 27.1111 11.5417 23.7084C14.9444 20.3056 18.9194 17.6153 23.4667 15.6375C28.0167 13.657 32.8611 12.6667 38 12.6667C42.3056 12.6667 46.4375 13.3611 50.3958 14.75C54.3542 16.1389 58.0694 18.1528 61.5417 20.7917L64.5625 17.7709C65.3264 17.007 66.2639 16.625 67.375 16.625C68.4861 16.625 69.4583 17.0417 70.2917 17.875C71.0555 18.6389 71.4375 19.6111 71.4375 20.7917C71.4375 21.9722 71.0555 22.9445 70.2917 23.7084L67.375 26.625C70.0139 30.0972 72.0278 33.8125 73.4167 37.7709C74.8055 41.7292 75.5 45.8611 75.5 50.1667C75.5 55.3056 74.5111 60.15 72.5333 64.7C70.5528 69.2472 67.8611 73.2222 64.4583 76.625C61.0556 80.0278 57.0806 82.7195 52.5333 84.7C47.9833 86.6778 43.1389 87.6667 38 87.6667Z"
        />
    </svg>

</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: 'navigation-icon-color'
            }
        }
    }
</script>
<style lang="scss" scoped>
.svg.blue {
    fill: var(--navigation-icon-color);
}

.svg.white {
    fill: var(--white);
}

.svg.star {
    fill: var(--star-color);
}
</style>
